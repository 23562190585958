import React, {useState, useContext, useEffect} from "react";
import { ResponsiveContext, Box, Paragraph } from "grommet";
import WindowPanel from "../components/windowPanel";
import ResizeIcon from "../images/ic_windowresize.inline.svg"


const ResizeBrowserScreen = ({browser, setBrowser}) => {
    const [show, setShow] = React.useState()
    const size = useContext(ResponsiveContext)
    const [isIEBrowser, setIEBrowser] = useState(false)
  
    useEffect(() => {
      if (typeof window !== `undefined`) {
        let isTouchDevice = function () {
          return "ontouchstart" in window || "onmsgesturechange" in window
        }
  
        setBrowser(
          !isTouchDevice() &&
            (size === "xxsmall" || size === "xsmall" || size === "small")
        )
        const isIE = /*@cc_on!@*/ false || !!document.documentMode
        setIEBrowser(isIE)
      }
    }, [size])
  
  
    return browser ? (
      <Box
        id="resize"
        fill={true}
        justify="center"
        align="center"
        background={"black"}
        style={{ position: "absolute", top: 0, left: 0, zIndex: 99999 }}
      >
        {browser && !isIEBrowser && (
          <Box pad="40px" aligin="center">
            <Box margin="40px" alignSelf="center">
              <ResizeIcon height={75} width={106} />
            </Box>
            <Paragraph fill={true} textAlign="center" style={{ fontSize: 14,letterSpacing: 1 }}>
              YOUR BROWSER WINDOW IS TOO SMALL, RESIZE YOUR SCREEN.
            </Paragraph>
          </Box>
        )}
        {browser && isIEBrowser && (
          <WindowPanel width={"590px"} color={"white"} title={"ALERT"}>
            <Paragraph fill={true} style={{ fontSize: 14 }}>
              SORRY, YOUR BROWSER IS NOT CURRENTLY SUPPORTED.{" "}
            </Paragraph>
            <Paragraph fill={true} style={{ fontSize: 14 }}>
              TO VIEW THIS EXPERIENCE, PLEASE UPGRADE TO A MODERN, SUPPORTED
              BROWSER SUCH AS{" "}
              <span style={{ fontWeight: 600 }}>CHROME, FIREFOX OR SAFARI.</span>
            </Paragraph>
          </WindowPanel>
        )}
      </Box>
    ) : null
  }

  export default ResizeBrowserScreen;