import { Box, Select, Text } from "grommet"
import React, { useState } from "react"

const FormFieldComponent = ({
  name,
  nameOveride,
  content,
  instructions,
  error,
  setErrors = null,
  ...rest
}) => {
  const [value, setValue] = useState("")

  return (
    <Box margin={{ top: "10px" }}>
      <Text size="xsmall" style={{ letterSpacing: 2 }}>
        {name.toUpperCase()}
      </Text>
      <Box border={{color: error ? "#fb3308" : "black"}} style={{top: 1, position: "relative"}}>
      <Select
        style={{
          fontWeight: 200,
          padding: 0,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        name={nameOveride || name.replace(/ /g, "").toLowerCase()}
        placeholder={name}
        options={content}
        value={value}
        onChange={({ option }) => {
          setErrors && setErrors({});
          setValue(option)
        }}
      />
      </Box>
     
      <Text size="xsmall" style={{ color: error ? "#fb3308" : "black" }}>
        {error ? error : instructions}
      </Text>
    </Box>
  )
}

export default FormFieldComponent
