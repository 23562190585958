import React from "react";
import { Box } from "grommet";
import backgroundImage from "../images/adidas_moodfilm_loop_poster.jpg";
import styled from "styled-components";


const ThreeSixty = styled(Box)`
  background-color: black;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

const ThreeSixtyComponent = () => {
  return (
    <ThreeSixty fill className="full-height" >
      <div className="vimeo-wrapper">
        <iframe
          src="https://player.vimeo.com/video/590947013/?background=1&autoplay=1&loop=1&byline=0&title=0"
          frameBorder="0"
          // webkitAllowFullScreen
          // mozAllowFullScreen
          allowFullScreen></iframe>
      </div>
    </ThreeSixty>
  );
};

export default ThreeSixtyComponent;
