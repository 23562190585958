import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ResizeBrowserScreen from "../components/mobileBrowserWarning";
import Framework from "../components/Framework";
import Gui from "../components/Gui";
import { useDispatch, useSelector } from "react-redux";
import { getProgress } from "../helpers/events";
import Button from "../components/button";
import { moveForward, moveTo } from "../state/globalSlice";
import ThreeSixtyVideo from "../components/ThreeSixtyVideo";
import WindowPanel from "../components/windowPanel";
import { Box, Form, Grid, ResponsiveContext } from "grommet";
import { usePageMargin } from "../helpers/hooks";

const Resume = ({ setResume, section }) => {
  const dispatch = useDispatch();
  const [pageMargin] = usePageMargin(25);

  const resumeSection = () => {
    dispatch(moveTo(section));
    setResume(false);
  };

  const startAgain = () => {
    setResume(false);
  };

  return (
    <>
      <ThreeSixtyVideo />
      <Box justify="center" align="center" fill={true} pad={`${pageMargin}px`} style={{ position: "absolute", top: 0, pointerEvents: "none" }}>
        <WindowPanel width={"590px"} color={"black"} title={"WELCOME BACK"}>
          <Box direction="row" justify="between">
          <Button color="white" onClick={resumeSection} margin={20} width={"48%"} height={48} type="submit" primary text="Resume" background={"black"} />
          <Button color="white" onClick={startAgain} margin={20} width={"48%"} height={48} type="submit" primary text="Restart" background={"black"} />
          </Box>
         
        </WindowPanel>
      </Box>
    </>
  );
};

const Intro = ({ location }) => {
  const global = useSelector((state) => state.global);
  const { sceneNumber, loading } = global;
  const [browser, setBrowser] = useState(false);
  const [resume, setResume] = useState(true);
  const [resumeScene, setResumeScene] = useState(true);

  useEffect(() => {
    let { sceneNumber } = getProgress();
    if (!sceneNumber) {
      setResume(false);
    } else {
      setResumeScene(sceneNumber);
    }
  }, []);

  return (
    <Layout background={"black"}>
      <SEO background={"black"} title="Virtual Event" />
      {resume ? <Resume section={resumeScene} setResume={setResume} /> : <Framework />}
      <ResizeBrowserScreen browser={browser} setBrowser={setBrowser} />
      <Gui pager={sceneNumber >= 0 && sceneNumber < 5 && !browser && !resume} page={sceneNumber} bottom={browser || resume} />
    </Layout>
  );
};
export default Intro;
