import React, { useState, useEffect } from "react"
import { Box, Text, CheckBox } from "grommet"

const FormFieldComponent = ({
  name,
  instructions,
  error,
  checked: checkedProp,
  setErrors = null,
  ...rest
}) => {
  const [checked, setChecked] = useState(!!checkedProp)
  const onChange = event => {
    setErrors && setErrors({})
    setChecked(event.target.checked)
  }

  return (
    <Box
      fill="horizontal"
      align={"start"} justify={"start"}
      pad={{ vertical: "small" }}
      direction="row"
    >
      <CheckBox
        {...rest}
        style={{marginTop: 5}}
        name={name.replace(/ /g, "").toLowerCase()}
        checked={checked}
        onChange={onChange}
      />
      <Text
        size="small"
        style={{ marginLeft: 10 }}
        dangerouslySetInnerHTML={{ __html: instructions }}
      ></Text>

      {error && (
        <Text
          dangerouslySetInnerHTML={{ __html: error }}
          size="xsmall"
          style={{
            marginLeft: 20,
            marginTop: 3,
            opacity: 1,
            color: error ? "#FB3308" : "black",
          }}
        ></Text>
      )}
    </Box>
  )
}

export default FormFieldComponent
